
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  getCurrentInstance,
  onMounted,
} from "vue";
import utils from "@/utils/util";
import BasicInfo from "./updateCv/basicInfo.vue";
import Expect from "./updateCv/expect.vue";
import WorkExperience from "./updateCv/workExperience.vue";
import ProjectExperience from "./updateCv/projectExperience.vue";
import EduExperience from "./updateCv/eduExperience.vue";
// import BasicInfoDetail from "./detailInfoCv/basicInfo.vue";
// import ExpectDetail from "./detailInfoCv/expect.vue";
// import WorkExperienceDetail from "./detailInfoCv/workExperience.vue";
// import ProjectExperienceDetail from "./detailInfoCv/projectExperience.vue";
// import EduExperienceDetail from "./detailInfoCv/eduExperience.vue";
import { uploadAction } from "@/api/common";
import { ElMessage } from "element-plus/lib/components";
import { recruitService } from "@/api";
import { useRouter, useRoute } from "vue-router";
import { Delete } from "@element-plus/icons-vue";
import ViewPdf from "@/components/pc/viewPdf.vue";
export default defineComponent({
  props: {},
  components: {
    BasicInfo,
    Expect,
    WorkExperience,
    ProjectExperience,
    EduExperience,
    Delete,
    // BasicInfoeDetail,
    // ExpectDetail,
    // WorkExperienceDetail,
    // ProjectExperienceDetail,
    // EduExperienceDetail,
    ViewPdf,
  },
  setup() {
    const instance = getCurrentInstance();
    const { proxy } = instance as any;
    const resumeRef = ref<any>();
    const basicInfo = ref<any>();
    const expect = ref<any>();
    const workExperience = ref<any>();
    const projectExperience = ref<any>();
    const eduExperience = ref<any>();
    const expectDetail = ref<any>();
    const workExperienceDetail = ref<any>();
    const projectExperienceDetail = ref<any>();
    const eduExperienceDetail = ref<any>();
    const route = useRoute();
    const router = useRouter();
    const state = reactive<{
      activeIndex: any;
      action: any;
      fileList: any;
      loading: boolean;
      isInit: boolean;
      accountId: any;
      basicInfoForm: any;
      expectForm: any;
      workExpList: any;
      projectExpList: any;
      eduExpList: any;
      showEditCpt: any;
      detail: any;
      fileUploadTime: any;
      originalResumeUrl: any;
      dialogVisible: boolean;
    }>({
      dialogVisible: false,
      originalResumeUrl: null,
      fileUploadTime: null,
      detail: null,
      showEditCpt: [true, true, true, true],
      activeIndex: 0,
      action: uploadAction,
      fileList: [],
      loading: true,
      isInit: false,
      accountId: null,
      basicInfoForm: {
        accountId: null,
        accountName: "",
        jobStatus: null,
        sex: null,
        idCard: "",
        birth: "",
        birthTime: "",
        age: "",
        mobile: "",
        email: "",
        hukouCode: "",
        currentCode: "",
        startWorkDate: "",
        startWorkTime: "",
        lastWorkUnit: "",
        weChat: "",
        selfEvaluate: "",
        headUrl: "",
      },
      expectForm: {
        jobType: 1,
        forwardLocationId: "",
        forwardPostName: "",
        forwardPostType: "",
        forwardMinSalary: "",
        forwardMaxSalary: "",
      },
      workExpList: [],
      projectExpList: [],
      eduExpList: [],
    });
    onMounted(() => {
      state.loading = true;
      recruitService.user
        .userDetail()
        .then((val) => {
          state.loading = false;
          if (val.data) {
            setDetailData(val.data);
            if (val.data.resumeUrl) {
              state.fileList = [
                {
                  name: val.data.resumeUrl.split("/").pop(),
                  url: val.data.resumeUrl,
                },
              ];
            }
            if (!val.data.headUrl) {
              state.basicInfoForm.headUrl =
                "https://image.offersaas.com/offercloud/2022/3/14/952879142695600128/defaultAvatar.png";
            }
            state.basicInfoForm = {
              accountId: val.data.accountId,
              accountName: val.data.accountName,
              jobStatus: val.data.jobStatus,
              sex: val.data.sex,
              idCard: val.data.idCard,
              birth: val.data.birth,
              birthTime: val.data.birth
                ? utils.dateFm(val.data.birth, "YYYY-MM-DD")
                : "",
              age: val.data.age,
              mobile: val.data.mobile,
              email: val.data.email,
              hukouCode: "",
              currentCode: "",
              startWorkDate: val.data.startWorkTime
                ? utils.dateFm(val.data.startWorkTime, "YYYY-MM-DD")
                : "",
              startWorkTime: val.data.startWorkTime,
              lastWorkUnit: val.data.lastWorkUnit,
              weChat: val.data.weChat,
              selfEvaluate: val.data.selfEvaluate,
              headUrl: val.data.headUrl
                ? val.data.headUrl
                : "https://image.offersaas.com/offercloud/2022/3/14/952879142695600128/defaultAvatar.png",
            };
            if (val.data.currentDistrictCode) {
              state.basicInfoForm.currentCode =
                `${val.data.currentProvCode},${val.data.currentCityCode},${val.data.currentDistrictCode}`.split(
                  ","
                );
              state.basicInfoForm.currentName = `${val.data.currentProvName}/${val.data.currentCityName}/${val.data.currentDistrictName}`;
            } else if (val.data.currentCityCode) {
              state.basicInfoForm.currentCode =
                `${val.data.currentProvCode},${val.data.currentCityCode}`.split(
                  ","
                );
              state.basicInfoForm.currentName = `${val.data.currentProvName}/${val.data.currentCityName}`;
            }
            if (val.data.hukouDistrictCode) {
              state.basicInfoForm.hukouCode =
                `${val.data.hukouProvCode},${val.data.hukouCityCode},${val.data.hukouDistrictCode}`.split(
                  ","
                );
              state.basicInfoForm.hukouName = `${val.data.hukouProvName}/${val.data.hukouCityName}/${val.data.hukouDistrictName}`;
            } else if (val.data.hukouCityCode) {
              state.basicInfoForm.hukouCode =
                `${val.data.hukouProvCode},${val.data.hukouCityCode}`.split(
                  ","
                );
              state.basicInfoForm.hukouName = `${val.data.hukouProvName}/${val.data.hukouCityName}`;
            }
            state.expectForm = {
              accountId: val.data.accountId,
              jobType: val.data.jobType || 1,
              forwardLocationId: val.data.forwardLocationId,
              forwardLocationIdList: val.data.forwardLocationId
                ? val.data.forwardLocationId.split(",")
                : "",
              forwardPostName: val.data.forwardPostName,
              forwardPostType: val.data.forwardPostType,
              forwardMinSalary: val.data.forwardMinSalary,
              forwardMaxSalary: val.data.forwardMaxSalary,
            };
            if (val.data.workExpList) {
              state.workExpList = val.data.workExpList.map((val: any) => {
                const daterange = [
                  utils.dateFm(val.startDate, "YYYY-MM-DD"),
                  utils.dateFm(val.endDate, "YYYY-MM-DD"),
                ];
                val["guid"] = utils.guid();
                return Object.assign(val, { daterange: daterange });
              });
            }
            if (val.data.projectExpList) {
              state.projectExpList = val.data.projectExpList.map((val: any) => {
                const daterange = [
                  utils.dateFm(val.startDate, "YYYY-MM-DD"),
                  utils.dateFm(val.endDate, "YYYY-MM-DD"),
                ];
                val["guid"] = utils.guid();
                return Object.assign(val, { daterange: daterange });
              });
            }
            if (val.data.eduExpList) {
              state.eduExpList = val.data.eduExpList.map((val: any) => {
                const daterange = [
                  utils.dateFm(val.startDate, "YYYY-MM-DD"),
                  utils.dateFm(val.endDate, "YYYY-MM-DD"),
                ];
                val["guid"] = utils.guid();
                return Object.assign(val, {
                  daterange: daterange,
                });
              });
            }
          }
          state.isInit = true;
        })
        .catch((val) => {
          state.loading = false;
          state.isInit = true;
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    });
    function setDetailData(obj: any) {
      state.detail = JSON.parse(JSON.stringify(obj));
      if (!state.detail.headUrl) {
        state.detail.headUrl =
          "https://image.offersaas.com/offercloud/2022/3/14/952879142695600128/defaultAvatar.png";
      }
      if (state.detail.currentDistrictCode) {
        state.detail.currentCode = `${state.detail.currentProvCode},${state.detail.currentCityCode},${state.detail.currentDistrictCode}`;
        state.detail.currentName = `${state.detail.currentProvName}/${state.detail.currentCityName}/${state.detail.currentDistrictName}`;
      } else if (state.detail.currentCityCode) {
        state.detail.currentCode = `${state.detail.currentProvCode},${state.detail.currentCityCode}`;
        state.detail.currentName = `${state.detail.currentProvName}/${state.detail.currentCityName}`;
      }
      if (state.detail.hukouDistrictCode) {
        state.detail.hukouCode = `${state.detail.hukouProvCode},${state.detail.hukouCityCode},${state.detail.hukouDistrictCode}`;
        state.detail.hukouName = `${state.detail.hukouProvName}/${state.detail.hukouCityName}/${state.detail.hukouDistrictName}`;
      } else if (state.detail.hukouCityCode) {
        state.detail.hukouCode = `${state.detail.hukouProvCode},${state.detail.hukouCityCode}`;
        state.detail.hukouName = `${state.detail.hukouProvName}/${state.detail.hukouCityName}`;
      }
      if (state.detail.resumeUrl) {
        state.originalResumeUrl = state.detail.resumeUrl;
        const resumeUrl = encodeURIComponent(state.detail.resumeUrl);
        state.detail.resumeUrl = `https://lg.offersaas.com/pdfjs/web/viewer.html?file=${resumeUrl}`;
        state.fileUploadTime = state.detail.resumeUrlTime;
        state.fileList = [
          {
            name: state.originalResumeUrl.split("/").pop(),
            url: state.originalResumeUrl,
          },
        ];
      }
    }
    function save() {
      recruitService.user
        .sendPost({ postId: route.query.postId })
        .then(() => {
          router.push(`/record`).catch((err) => {
            console.warn(err);
          });
          ElMessage.success("投递成功！");
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    }
    function changeHash(name: string, index: number) {
      state.activeIndex = index;
      const el: any = document.querySelector(`#${name}`);
      el.scrollIntoView(true);
      // document.querySelector(`#${name}`).scrollIntoView(true);
    }
    // function beforeUpload() {
    //   return new Promise<void>((resolve, reject) => {
    //     if (!state.accountId) {
    //       ElMessage.error("请先保存基本信息后，再上传简历！");
    //       reject();
    //     } else {
    //       resolve();
    //     }
    //   });
    // }
    function handleExceed() {
      ElMessage.error("只能上传一份简历！");
    }
    function handleSuccess(response: any, file: any, fileList: any[]) {
      state.loading = true;
      if (response.code === 0) {
        file.name = file.name.replace(/,/g, "_");
        file.url = response.data;
        updateResumeUrl(response.data);
      } else {
        const index = fileList.findIndex((d) => d.uid === file.uid);
        if (~index) {
          fileList.splice(index, 1);
        }
        ElMessage.error(response.message || "错误");
      }
      proxy.$refs["resumeRef"].clearFiles();
      state.fileList = fileList;
      state.loading = false;
    }
    function handleRemove() {
      updateResumeUrl();
    }
    function updateResumeUrl(url?: string) {
      state.loading = true;
      const obj = {
        resumeUrl: url,
      };
      recruitService.user
        .updateResumeUrl(obj)
        .then((val) => {
          state.loading = false;
          if (url) {
            state.originalResumeUrl = val.data.resumeUrl;
            const resumeUrl = encodeURIComponent(val.data.resumeUrl);
            state.detail.resumeUrl = `https://lg.offersaas.com/pdfjs/web/viewer.html?file=${resumeUrl}`;
            state.fileUploadTime = val.data.resumeUrlTime;
            ElMessage.success("上传成功!");
          } else {
            state.originalResumeUrl = null;
            state.detail.resumeUrl = null;
            state.fileUploadTime = null;
            ElMessage.success("删除成功!");
          }
        })
        .catch((val) => {
          state.loading = false;
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    }
    function setBasicInfo(info: any) {
      state.basicInfoForm = info;
      state.accountId = info.accountId;
    }
    function closeDialog(index: number) {
      state.showEditCpt[index] = false;
    }
    return {
      ...toRefs(state),
      resumeRef,
      save,
      route,
      changeHash,
      basicInfo,
      expect,
      workExperience,
      projectExperience,
      eduExperience,
      expectDetail,
      workExperienceDetail,
      projectExperienceDetail,
      eduExperienceDetail,
      handleSuccess,
      handleExceed,
      handleRemove,
      setBasicInfo,
      closeDialog,
      toPdf() {
        state.dialogVisible = true;
      },
      handleClose(done: () => void) {
        state.dialogVisible = false;
        done();
      },
    };
  },
});
